// Offcanvas Funcionality

for (let navtoggler of document.querySelectorAll('.headerbar__content-navtoggler,.headerbar__content-mainnavcontainer-navtoggler')) {
  navtoggler.addEventListener('click', () => {
    document.body.classList.toggle('activenav');
  })
}

// Add Pageclass on Scroll

document.addEventListener('scroll', () => {
  if (window.scrollY > 0) {
    document.body.classList.add('scrolled')
    return;
  }
  document.body.classList.remove('scrolled')
})

// Navigation Functionality
for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth < 1200) {
      e.preventDefault();
      parentMenuItem.classList.toggle('clicked');
    }
  })
}

for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth >= 1200) {
      e.preventDefault();
      if (parentMenuItem.classList.contains('clicked')) {
        parentMenuItem.classList.remove('clicked');
        document.body.classList.remove('activedropdownnav');
        return;
      }
      for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent')) {
        parentMenuItem.classList.remove('clicked');
      }
      parentMenuItem.classList.toggle('clicked');
      document.body.classList.toggle('activedropdownnav');
    }
  })
}

document.querySelector('.menubackground').addEventListener('click', () => {
  if (window.innerWidth >= 1200) {
    for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent')) {
      parentMenuItem.classList.remove('clicked');
    }
    document.body.classList.remove('activedropdownnav');
  }
})

for (let subnavCloser of document.querySelectorAll('.subnavcloser')) {
  subnavCloser.addEventListener('click', () => {
    for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent')) {
      parentMenuItem.classList.remove('clicked');
    }
    document.body.classList.remove('activedropdownnav');
  })
}

// Accordion

for (let accordionitem of document.querySelectorAll('.accordion__item')) {
  accordionitem.querySelector('.accordion__item-title').addEventListener('click', () => {
    accordionitem.classList.toggle('accordion__item--open')
  })
}

// APT Modal

for (let modalCloser of document.querySelectorAll('.aptmodal__closer')) {
  modalCloser.addEventListener('click', () => {
    modalCloser.closest('.aptmodal').classList.remove('aptmodal__active');
  })
}